<!-- 主页 -->
<template>
  <div class="page-main"  id="message">
    <div v-if="loading" class="login-loading">
      <van-loading type="spinner" vertical>
        加载中...
      </van-loading>
    </div>
    <div v-else class="nut-card">
      <!-- 主页 -->
      <div class="nut-title">
        <div class="nut-name">
          <van-icon name="arrow-left" size="20" color="#333" @click="goBack" />
          <div class="nut-name-desc">
            历史记录
          </div>
        </div>
      </div>
      <div class="nut-module">
        <div class="nut-card">
          <div class="nut-item" v-for="item in dataList" :key="item.id">
            <div class="item-info" @click="goChat(item.content)">
              <img src="@/assets/image/history-item.png" class="file-img">
              <div style="margin-left:10px">
                <div class="item-content">
                  {{item.content}}
                </div>
                <div>
                  {{item.is_finish == 1 ? '已完成': '未完成'}}
                  <span style="margin-left:20px;color:red" v-if="item.is_finish == 1 && item.is_risk == 1">
                    存在风险
                  </span>
                </div>
              </div>
            </div>
            <div>
              <van-icon name="delete-o" @click="deleteItem(item.id)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { setSession } from "../../utils/sessionUtils"
import CommonData from "../../utils/commonData"
import { Toast, Dialog } from 'vant'

export default {
  name: "home",
  components: {
  },
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      console.log('home=====>')
      //获取传入参数
      data.loading = false
      const options = $router.currentRoute.value.query;
    });
    const goBack = () => {
      $router.go(-1)
    }
    const data = reactive({
      loading: true,
      active: 1,
      dataList: [
        {id:1, type: 1, content: '营养风险筛查（NRS-2002）', is_finish: 1, is_risk: 1},
        {id:2, type: 1, content: '儿童主观整体营养评估-婴幼儿(SGNA)', is_finish: 1, is_risk: 0},
        {id:5, type: 1, content: '儿科营养不良筛查表（STAMP）', is_finish: 0, is_risk: 0}
      ]
    });
    const deleteItem = id => {
      Dialog.confirm({
        message:
          '确认删除当前记录？',
      })
      .then(() => {
        // on confirm
        var index = data.dataList.findIndex(o => o.id == id)
        data.dataList.splice(index, 1);
      })
      .catch(() => {
        // on cancel
      });
    }
    const goChat = desc => {
      const params = {
        title: desc,
        is_finish: 1
      }
      $router.push({
        name: 'chat',
        query: params
      })
    }
    return {
      ...toRefs(data),
      goBack,
      deleteItem,
      goChat,
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  background: #EEE;
  .login-loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nut-title {
    width: 100%;
    background: rgb(187, 255, 238, 0.2);
    .nut-name {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0 20px;
      .nut-name-desc {
        width: calc(100% - 40px);
        text-align: center;
        font-size: 18px;
        line-height: 40px;
        color: #333;
      }
    }
  }
  .nut-module {
    .nut-card {
      .nut-item {
        background: #fff;
        margin: 10px;
        padding: 5px;
        border-radius: 5px;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 16px;
        min-height: 40px;
        .item-info {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          .item-content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        
        .file-img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

</style>
