// 存储session

export const setSession = (key, val) => {
  return sessionStorage.setItem(key, val)
}

export const getSession = (key) => {
  return sessionStorage.getItem(key)
}

export const delSession = (key) => {
  return sessionStorage.removeItem(key)
}

export const clearAllSession = () => {
  return sessionStorage.clear()
}
